import { styled } from '@mui/material/styles';

import Ekomi from './Ekomi';
import TuvCertificate from './TuvCertificate';
import { GuaranteeBadge } from './Badges/GuaranteeBadge';

const TrustPartner = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const EkomiWrapper = styled('div')(({ theme }) => ({
  margin: `auto ${theme.spacing(2)}`,
}));

export const LegalPartner = ({
  showBlackFridayBadge,
}: {
  showBlackFridayBadge?: boolean;
}) => (
  <TrustPartner>
    {showBlackFridayBadge && <GuaranteeBadge />}
    <TuvCertificate />
    <EkomiWrapper>
      <Ekomi />
    </EkomiWrapper>
  </TrustPartner>
);
