import useSWR from 'swr';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';

import { Slider } from 'components/Slider';
import Testimonial from './Testimonial';
import { EKOMI_TESTIMONIALS_API_ROUTE } from 'lib/constants/api';

import type { Testimonial as TestimonialType } from 'lib/api-routes/ekomi/types';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const Background = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
}));

const HeadingContainer = styled(Container)`
  padding: 0;
`;

const SliderContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

export const EkomiTestimonials = ({ title }: { title: string }) => {
  const { data, error, isLoading } = useSWR(
    EKOMI_TESTIMONIALS_API_ROUTE,
    fetcher,
  );

  if (error || isLoading) return null;

  const testimonials = data as TestimonialType[];
  if (!testimonials.length) return null;

  return (
    <Background>
      <Box py={8}>
        {title && (
          <Box mb={4} px={2}>
            <HeadingContainer maxWidth="lg">
              <Typography variant="h2">{title}</Typography>
            </HeadingContainer>
          </Box>
        )}
        <SliderContainer maxWidth="lg">
          <Slider>
            {testimonials?.map((testimonial) => (
              <Testimonial key={testimonial.submitted} data={testimonial} />
            ))}
          </Slider>
        </SliderContainer>
      </Box>
    </Background>
  );
};
