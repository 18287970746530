import { getGenericTrackingData } from '@finanzcheck/one-segment';
import { ANDROID, IOS, getOsName } from 'lib/router/route-helper';
import { trackButtonClick } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';
import { USER_ID_COOKIE_NAME } from 'lib/optimizely/getUserId';
import { getCookie } from 'lib/cookies/cookieHelper';
import { ArrowForward } from '@mui/icons-material';
import { Variation } from 'lib/optimizely/flagConfigs';

export const APP_BUTTON_TEXT = 'Kreditvergleich in-App starten';
export const APP_BANNER_TITLE =
  'Ihr Kreditvergleich nur einen Fingertipp entfernt.';

export const trackAppRedirectionClick = (buttonName?: ButtonName) => {
  trackButtonClick(
    TrackingEvent.REDIRECT_TO_APP,
    {
      device: getOsName(),
      ...getGenericTrackingData(),
      buttonName,
    },
    {
      context: {
        Optimizely: {
          userId: getCookie(USER_ID_COOKIE_NAME),
          eventName: TrackingEvent.REDIRECT_TO_APP,
        },
      },
    },
  );
};

export enum AppParams {
  OrigFCID = 'orig_FCID',
  OrigAnonymousId = 'orig_anonymous_id',
}

const getAnonymousId = async () => {
  if (window.analytics) {
    try {
      const user = await window.analytics.user();
      const id = user.anonymousId();
      return id;
    } catch (error) {
      console.error('Error getting anonymousId:', error);
    }
  }
};

export const getRedirectionLink = async () => {
  const anonymousId = await getAnonymousId();
  const baseUrl = 'https://smava.go.link';
  const params = new URLSearchParams({
    adj_t: '1ji1x9d4',
    adj_campaign: 'test_2024-12-13',
    adj_label: getCookie(USER_ID_COOKIE_NAME) || '',
    [AppParams.OrigFCID]: getCookie(USER_ID_COOKIE_NAME) || '',
    [AppParams.OrigAnonymousId]: anonymousId || '',
  });

  return `${baseUrl}?${params.toString()}`;
};

export const redirectToCorrectStore = async (buttonName?: ButtonName) => {
  trackAppRedirectionClick(buttonName);

  const osName = getOsName();
  if (osName === IOS || osName === ANDROID) {
    const redirectionLink = await getRedirectionLink();
    window.location.href = redirectionLink;
  }
};

export const getAppRedirectionExperimentStageConfig = (
  appRedirectionVariation: string,
) => ({
  ctaText:
    appRedirectionVariation === Variation.VARIATION_D ||
    appRedirectionVariation === Variation.VARIATION_E
      ? 'Kreditvergleich im Browser starten'
      : undefined,
  endIcon:
    appRedirectionVariation === Variation.VARIATION_C ||
    appRedirectionVariation === Variation.VARIATION_B ||
    appRedirectionVariation === Variation.VARIATION_D ||
    appRedirectionVariation === Variation.VARIATION_E ? (
      <ArrowForward />
    ) : null,
  isVideoHeroSectionEnabled: appRedirectionVariation === Variation.VARIATION_E,
  isTopLightBannerEnabled: appRedirectionVariation === Variation.VARIATION_C,
  isOverlayEnabled: appRedirectionVariation === Variation.VARIATION_C,
});

export const getAppRedirectionExperimentLoanSelectorConfig = (
  appRedirectionVariation?: string | null,
) => ({
  areButtonsSwapped: appRedirectionVariation === Variation.VARIATION_D,
  isAlternativeCTAButtonVisible:
    appRedirectionVariation === Variation.VARIATION_C ||
    appRedirectionVariation === Variation.VARIATION_B ||
    appRedirectionVariation === Variation.VARIATION_D,
  isMobileStoresBadgesComponentVisible:
    appRedirectionVariation === Variation.VARIATION_B,
  isMobileStoresBadgesBlackComponentVisible:
    appRedirectionVariation === Variation.VARIATION_D,
});

export const getAppRedirectionExperimentIndexConfig = (
  appRedirectionVariation?: string | null,
) => ({
  isStickyBannerVisible: appRedirectionVariation === Variation.VARIATION_B,
  showTvBanner:
    appRedirectionVariation !== Variation.VARIATION_B &&
    appRedirectionVariation !== Variation.VARIATION_C &&
    appRedirectionVariation !== Variation.VARIATION_D &&
    appRedirectionVariation !== Variation.VARIATION_E,
});

export enum ButtonName {
  // variation B
  BUTTON_TOP_STICKY_BANNER = 'button_top_sticky_banner',

  // variation C
  BUTTON_OVERLAY = 'button_overlay',
  BUTTON_LIGHT_GREEN_BANNER = 'button_light_green_banner',

  // variation D
  // variation E
  BUTTON_VIDEO = 'button_video',

  // all variations
  BUTTON_DOUBLE_CTA_ALTERNATIVE = 'button_double_cta_alternative',
}
