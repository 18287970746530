import { styled } from '@mui/material/styles';
import { EkomiWidget } from 'components/Ekomi/EkomiWidget';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default function Ekomi() {
  const redirectToEkomi = () => {
    window.open(
      'https://www.ekomi.de/bewertungen-smava-gmbh.html',
      '_blank', // <- This is what makes it open in a new window.
    );
  };

  return (
    <Wrapper onClick={redirectToEkomi}>
      <EkomiWidget />
    </Wrapper>
  );
}
