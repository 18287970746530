import Link from 'next/link';
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getGenericTrackingData } from '@finanzcheck/one-segment';

import { Gallery } from './Gallery';
import Slider from './Slider';
import { track } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';

const Wrapper = styled(Container)(({ theme }) => ({
  padding: `${theme.spacing(6)} 0 ${theme.spacing(1)} 0`,
  display: 'block',
  overflowX: 'hidden',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(5, 0),
  },
}));

const HideOnTablet = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const HideOnDesktop = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[600],
  paddingTop: theme.spacing(2),
  display: 'block',
  width: 'max-content',
  textDecoration: 'underline',
  float: 'right',
  p: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
    textAlign: 'right',
  },
  [theme.breakpoints.up('md')]: {
    color: theme.palette.grey[900],
    p: {
      textAlign: 'left',
      padding: `0`,
    },
    float: 'left',
  },
}));

const desktopAssetList = [
  '/assets/partner-banks/targobank.svg',
  '/assets/partner-banks/vvrb.svg',
  '/assets/partner-banks/creditplus.svg',
  '/assets/partner-banks/ingdiba.svg',
  '/assets/partner-banks/santander.svg',
  '/assets/partner-banks/postbank.svg',
  '/assets/partner-banks/skreditpartnerkredit.svg',
  '/assets/partner-banks/commerzbank.svg',
  '/assets/partner-banks/auxmoney.svg',
  '/assets/partner-banks/hvb.svg',
  '/assets/partner-banks/bankofscotland.svg',
  '/assets/partner-banks/dkb.svg',
  '/assets/partner-banks/vonessensubprime.svg',
  '/assets/partner-banks/deutschebank.svg',
];

const tabletAssetList = [
  '/assets/partner-banks/hvb.svg',
  '/assets/partner-banks/vonessensubprime.svg',
  '/assets/partner-banks/skreditpartnerkredit.svg',
  '/assets/partner-banks/targobank.svg',
  '/assets/partner-banks/vvrb.svg',
  '/assets/partner-banks/creditplus.svg',
  '/assets/partner-banks/ingdiba.svg',
  '/assets/partner-banks/santander.svg',
  '/assets/partner-banks/postbank.svg',
  '/assets/partner-banks/commerzbank.svg',
  '/assets/partner-banks/auxmoney.svg',
  '/assets/partner-banks/bankofscotland.svg',
  '/assets/partner-banks/dkb.svg',
  '/assets/partner-banks/deutschebank.svg',
];

export const PartnerBanks = () => {
  const handleLinkClick = () => {
    track(TrackingEvent.BANK_LIST_LINK_CLICKED, {
      ...getGenericTrackingData(),
    });
  };

  return (
    <Wrapper maxWidth="lg">
      <HideOnTablet>
        <Gallery bankLogoUrls={desktopAssetList} />
      </HideOnTablet>
      <HideOnDesktop>
        <Slider bankLogoUrls={tabletAssetList} />
      </HideOnDesktop>
      <StyledLink
        href="https://www.smava.de/kredit/partner/"
        onClick={handleLinkClick}
      >
        <Typography variant="body2">
          Teilnehmende Kreditbanken/Vermittler
        </Typography>
      </StyledLink>
    </Wrapper>
  );
};
