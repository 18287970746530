import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import LegalTextWrapper from 'components/LegalText';
import { useDecision } from 'lib/optimizely';
import { ABTestKeys, BLACK_FRIDAY_FLAG } from 'lib/optimizely/flagConfigs';

// Size may not be smaller than 14 on desktop
// due to legal reasons
// We have a cease and desist on that
// If in doubt, talk to legal dept.
export const TrustText = styled(Typography)(({ theme }) => ({
  fontSize: '8px',
  color: '#ababab',
  wordBreak: 'break-word',
  lineHeight: '13px',
  margin: `${theme.spacing(1)} 0`,
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    color: '#323232',
    lineHeight: '20px',
  },
  '& a': {
    fontWeight: 500,
    color: '#39A949',
    '&:hover': {
      opacity: 0.75,
    },
  },
}));

export const TvTrustText = styled(Typography)(({ theme }) => ({
  fontSize: '8px',
  color: '#848484',
  wordBreak: 'break-word',
  margin: `${theme.spacing(1)} 0`,
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    color: '#848484',
  },
}));

export const LegalText = () => {
  return (
    <>
      <TrustText>
        1 Repr. Bsp. gemäß §6a PAngV: Nettodarlehensbetrag: 1.000 €, Laufzeit:
        24 Monate, 24 Raten zu je 41,49 €, eff. Jahreszins: -0,40 %, gebundener
        Sollzins: -0,40 % p.a., Gesamtbetrag: 995,50 €. SolarisBank AG,
        Cuvrystraße 53, 10997 Berlin.
      </TrustText>
      <TrustText>
        2 Weitere Informationen sowie die Garantie-Bedingungen{' '}
        <a href="https://www.smava.de/kredit/guenstiger">finden Sie hier</a>
      </TrustText>
    </>
  );
};

export const LegalText32Percent = () => {
  return (
    <>
      <TrustText>
        1 Vergleich der eff. Jahreszinssätze neu vergebener Konsumentenkredite
        deutscher Banken (lt. Deutscher Bundesbank) mit den eff. Jahreszinssätze
        von über smava neu vermittelten Konsumentenkredite. Mehr Infos:{' '}
        <a href="https://www.smava.de/kreditvergleich">
          https://www.smava.de/kreditvergleich/
        </a>
      </TrustText>
      <TrustText>
        2 Weitere Informationen sowie die Garantie-Bedingungen{' '}
        <a href="https://www.smava.de/kredit/guenstiger">finden Sie hier</a>
      </TrustText>
    </>
  );
};

export const LegalTextShort = () => {
  return (
    <>
      <TrustText>
        &#42; Weitere Informationen sowie die Garantie-Bedingungen{' '}
        <a href="https://www.smava.de/kredit/guenstiger">finden Sie hier</a>
      </TrustText>
    </>
  );
};

export const LegalTextGoogleFooter = ({
  showRadioText,
}: {
  showRadioText?: boolean;
}) => {
  const [decision] = useDecision(ABTestKeys.CAT3106_Black_friday_banner);
  const showBlackFridayBadge = decision.variationKey === BLACK_FRIDAY_FLAG;

  return (
    <LegalTextWrapper isSEAPage>
      <br />
      <b>0,68% Zinsen:</b> 2/3 aller Kunden erhalten bei einem
      Nettodarlehensbetrag 50.000,00 €; 60 Monate Laufzeit; 7,99% effektiver
      Jahreszins; 7,71% p.a. gebundener Sollzins; 60 mtl. Raten zu je 1.006,93
      €; 60.415,84 € Gesamtbetrag; Vereinigte Volksbank Raiffeisenbank eG,
      Darmstädter Str. 62, 64354 Reinheim. (§17 PAngV)
      <br />
      <br />
      {showBlackFridayBadge ? (
        <>
          * Kreditvergleich mit aufgestockter Günstiger-Geht-Nicht-Garantie.
          Weitere Informationen sowie die Garantie-Bedingungen{' '}
          <a href="https://www.smava.de/kredit/guenstiger">finden Sie hier</a>
        </>
      ) : (
        <>
          * <a href="https://www.smava.de/kredit/guenstiger/">Mehr Infos</a>
        </>
      )}
      <br />
      ** <a href="https://www.smava.de/kredit/info/">Mehr Infos</a>
      <br />
      {showRadioText && (
        <>
          *** ARD: Morgenmagazin (02.02.2023); ZDF: Morgenmagazin (02.02.2023);
          NTV: Telebörse (25.10.2023); RTL: Guten Morgen Deutschland
          (06.12.2021).
        </>
      )}
    </LegalTextWrapper>
  );
};
