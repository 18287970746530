import { SMAVA_CATEGORY, FINANZCHECK_PURPOSE } from './categories';

export interface SmavaUrlParams {
  amount?: string;
  category?: SMAVA_CATEGORY;
  duration?: number; // in month;
  header_variant?: unknown;
  initialPayment?: number;
  router?: string;
  verhiclePrice?: number;
  advertisementId?: string;
  formConfig?: string;
}

export interface FinanzcheckUrlParams {
  amount?: number;
  advertisementId?: string;
  term?: number; // in month
  purpose?: FINANZCHECK_PURPOSE;
  resume?: string;
  formConfig?: string;
}

interface AnyObject {
  [key: string]: any;
}

const hasFinanzcheckUrlParams = (
  params: FinanzcheckUrlParams | SmavaUrlParams,
): params is FinanzcheckUrlParams => {
  if (Object.keys(params).length === 0) return true;
  if (Object.keys(params).length === 1 && params.amount) return true;
  const paramKeys = Object.keys(params);
  const finanzcheckKeys: Array<keyof FinanzcheckUrlParams> = [
    'term',
    'purpose',
    'resume',
  ];
  // @ts-ignore
  return paramKeys.some((key) => finanzcheckKeys.includes(key));
};

const getPurpose = (
  category: SMAVA_CATEGORY | FINANZCHECK_PURPOSE | string,
): SMAVA_CATEGORY | FINANZCHECK_PURPOSE | string | undefined => {
  const parsedCategory =
    typeof category === 'string' ? parseInt(category) : category;

  switch (parsedCategory) {
    case SMAVA_CATEGORY.FREE_USE:
      return FINANZCHECK_PURPOSE.OTHER;
    case SMAVA_CATEGORY.CAR:
      return FINANZCHECK_PURPOSE.PRE_OWNED_CAR;
    case SMAVA_CATEGORY.LIVING:
      return FINANZCHECK_PURPOSE.RENOVATION;
    case SMAVA_CATEGORY.MORTGAGE:
      return FINANZCHECK_PURPOSE.REAL_ESTATE_FINANCING;
    case SMAVA_CATEGORY.RESCHEDULE:
      return FINANZCHECK_PURPOSE.REFINANCING;
    case SMAVA_CATEGORY.BUSINESS:
      return FINANZCHECK_PURPOSE.OTHER_GEWERBE;
    default:
      return category;
  }
};

const DEFAULT_AMOUNT = 30000;
const DEFAULT_TERM = 84;
const DEFAULT_PURPOSE = FINANZCHECK_PURPOSE.OTHER;

export function urlParamsTranslator(query: AnyObject): AnyObject {
  let advertisementId,
    term,
    purpose,
    resume = undefined;
  const newQuery = { ...query };
  const amount = newQuery.amount
    ? Number(String(newQuery.amount).replace('.', ''))
    : DEFAULT_AMOUNT;

  if (hasFinanzcheckUrlParams(newQuery)) {
    advertisementId = newQuery.advertisementId;
    term = newQuery.term ? Number(newQuery.term) : DEFAULT_TERM;
    purpose = newQuery.purpose || DEFAULT_PURPOSE;
    resume = newQuery.resume;
  } else {
    // smava params incoming
    purpose = getPurpose(newQuery.category) || DEFAULT_PURPOSE;
    term = newQuery.duration ? Number(newQuery.duration) : DEFAULT_TERM;
    advertisementId = newQuery.advertisementId;

    // remove smava params from query
    delete newQuery.category;
    delete newQuery.duration;
    delete newQuery.initialPayment;
    delete newQuery.router;
    delete newQuery.verhiclePrice;
  }

  return {
    ...newQuery,
    amount,
    advertisementId,
    term,
    purpose,
    resume,
  };
}
