import React from 'react';
import Bugsnag, { Stackframe } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const isOnline = () => typeof navigator !== 'undefined' && navigator.onLine;

// This is experimental and not all browsers support this
const getNetworkConnection = () => {
  if (typeof navigator !== 'undefined') {
    return {
      downlink: (navigator as any).connection?.downlink,
      effectiveType: (navigator as any).connection?.effectiveType,
      rtt: (navigator as any).connection?.rtt,
    };
  }
  return {};
};

const getNetworkStatus = () => {
  return {
    online: isOnline(),
    connection: getNetworkConnection(),
  };
};

const appVersion = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF
  ? `${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}`
  : 'development';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_BROWSER_API_KEY || '',
  collectUserIp: false,
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ['live', 'stage', 'shared'], // add 'development' to enable bugsnag in development
  releaseStage: process.env.BUGSNAG_RELEASE_STAGE || 'development',
  appVersion,
  // Adding error meta data and error handling steps for specific error types
  onError: (event) => {
    // We are dismissing some errors that are not relevant for us
    const isAdBlockerError =
      event.errors[0]?.errorClass === 'TypeError' &&
      event.errors[0]?.errorMessage === 'Failed to fetch';
    if (isAdBlockerError) return false;

    // We continually get errors from people who copied the website to their local machine
    // and are trying to use the website without a network connection. CAT-3302
    // iterate though event.originalError.stacktrace to see if any entry contains 'file://'
    const stacktrace: Stackframe[] = event.originalError.stacktrace;
    if (stacktrace.some((frame) => frame.file?.startsWith('file://'))) {
      return false;
    }

    // Add network connection status to all errors
    event.addMetadata('network', getNetworkStatus());

    return true; // Return true to send the error to Bugsnag
  },
});

export const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React);

export default Bugsnag;
