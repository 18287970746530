import { Box, Container, Grid, Typography } from '@mui/material';

const INFO_TITLE = 'Ratenkredit Konditionen Übersicht';
const INFO_DATA = [
  {
    label: 'Zinssätze',
    value: 'Min. -0,40 % bis Max. 19,90 % effektiver Jahreszins (APR)',
  },
  {
    label: 'Laufzeit',
    value: 'Min. 6 bis Max. 120 Monate',
  },
  {
    label: 'Nettodarlehensbetrag',
    value: 'von 1.000 € bis 150.000 €',
  },
  {
    label: 'Gesamtbetrag',
    value: 'von 995,50 € bis 181.247,51 €',
  },
];

export const LegalTextGoogle = () => {
  return (
    <Container maxWidth="lg">
      <Box py={5} display="flex">
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            display="flex"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="body2"
                color={(theme) => theme.palette.grey[900]}
              >
                <b>Beispielrechnung gemäß PAngV</b>: Nettodarlehensbetrag
                20.000,00 €, 84 Monate Laufzeit, 5,39 % effektiver Jahreszins,
                5,26 % p.a. gebundener Sollzins, 84 mtl. Raten zu je 285,14 €,
                23.951,75 € Gesamtbetrag, Lloyds Bank GmbH, c/o Bank of
                Scotland, Karl-Liebknecht-Straße 5, 10178 Berlin
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Box
              sx={(theme) => ({
                padding: 2,
                borderRadius: 4,
                backgroundColor: {
                  xs: theme.palette.grey[100],
                  md: theme.palette.grey[50],
                },
              })}
            >
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600 }}
                sx={{ marginBottom: 2 }}
              >
                {INFO_TITLE}
              </Typography>

              {INFO_DATA.map((info, index) => (
                <Box
                  key={info.label}
                  paddingBottom={index === INFO_DATA.length - 1 ? 0 : 1}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                  }}
                >
                  <Typography variant="body2" minWidth="180px">
                    <b>{info.label}:</b>
                  </Typography>
                  <Typography variant="body2">{info.value}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
