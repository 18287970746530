/**
 * Renders the TopLightBanner component.
 * It's a component used by Variation C of the App Redirection Experiment CAT-3151.
 *
 * @component
 * @example
 * return (
 *   <TopLightBanner />
 * )
 */
import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  APP_BANNER_TITLE,
  ButtonName,
  getRedirectionLink,
  trackAppRedirectionClick,
} from './helpers';

const TopLightBanner: React.FC = () => {
  const [redirectionLink, setRedirectionLink] = React.useState<string>('');

  React.useEffect(() => {
    const fetchLink = async () => {
      const link = await getRedirectionLink();
      setRedirectionLink(link);
    };

    fetchLink();
  }, []);

  return (
    <Box
      display={{ xs: 'block', md: 'none' }}
      width="100%"
      mt={(theme) => `-${theme.spacing(3)}`}
      mb={(theme) => theme.spacing(2)}
      p={(theme) => theme.spacing(2)}
      bgcolor="#F6FDF5" // (theme) => theme.palette.primary[50]
    >
      <Typography
        variant="subtitle2"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={(theme) => theme.spacing(0.5)}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {APP_BANNER_TITLE}
        </Box>
        <Link
          onClick={() =>
            trackAppRedirectionClick(ButtonName.BUTTON_LIGHT_GREEN_BANNER)
          }
          href={redirectionLink}
          bgcolor={(theme) => theme.palette.common.white}
        >
          <Button
            data-test-id="CTA"
            variant="outlined"
            fullWidth
            color="primary"
            type="button"
            endIcon={<FileDownloadOutlinedIcon />}
            sx={{
              maxHeight: (theme) => theme.spacing(4.5),
              paddingLeft: (theme) => theme.spacing(2),
              paddingRight: (theme) => theme.spacing(2),
            }}
          >
            Download
          </Button>
        </Link>
      </Typography>
    </Box>
  );
};

export { TopLightBanner };
