import Head from 'next/head';
import { smava } from '@finanzcheck/teal-mui-theme';

import {
  getJsonLDsForSchema,
  getWebsiteJsonLD,
  getOrganizationJsonLD,
} from 'lib/jsonLD/jsonLDhelper';
import { LINKED_DATA_SCHEMA } from 'lib/constants/jsonld';

export const DEFAULT_TITLE =
  'SMAVA Online-Kreditvergleich | unabhängig und kundenorientiert';
export const DEFAULT_DESCRIPTION =
  'SMAVA Kredite vergleichen: ☎ Telefonische Expertenberatung ✓ Exklusive Kreditangebote ✓ Schufaneutral ✓ Schnell und unkompliziert ➤ Zum günstigen Kredit!';
export const DEFAULT_KEYWORDS =
  'Kreditvergleich, Online-Kreditvergleich, Kredit vergleichen';
export const DEFAULT_TITLE_OPEN_GRAPH = DEFAULT_TITLE;
export const DEFAULT_DESCRIPTION_OPEN_GRAPH = DEFAULT_DESCRIPTION;

interface MetaHeadProps {
  title?: string;
  description?: string;
  index?: boolean;
  follow?: boolean;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  datePublished?: string;
  dateModified?: string;
  keywords?: string;
  markupSchema?: string;
}

export const MetaHead = (props: MetaHeadProps) => {
  const {
    title,
    description,
    keywords,
    index = false,
    follow = false,
    ogTitle,
    ogDescription,
    ogImage,
    markupSchema = LINKED_DATA_SCHEMA.none,
  } = props;

  // We ONLY allow indexing on production. This is to prevent indexing of
  // staging environments and other non-production environments.
  // This sucks for testing, but the danger is too real.
  // If you need to test indexing, you can set the RELEASE_STAGE to 'live'
  // @ts-ignore
  const isIndexingAllowed = process.env.RELEASE_STAGE === 'live';
  const indexString = index && isIndexingAllowed ? 'index' : 'noindex';
  const followString = follow && isIndexingAllowed ? 'follow' : 'nofollow';

  const FALLBACK_OG_IMAGE_URL = `${process.env.SMAVA_BASE_URL}/assets/images/og_smava2.webp`;
  const FALLBACK_OG_IMAGE_WIDTH = 1200;
  const FALLBACK_OG_IMAGE_HEIGHT = 630;

  const ogImageUrl = ogImage || FALLBACK_OG_IMAGE_URL;
  const ogImageWidth = FALLBACK_OG_IMAGE_WIDTH;
  const ogImageHeight = FALLBACK_OG_IMAGE_HEIGHT;

  const touchIconUrl = `${process.env.SMAVA_BASE_URL}/apple-touch-icon.png`;

  const includedJsonLDs = getJsonLDsForSchema(
    index ? markupSchema : LINKED_DATA_SCHEMA.none,
  );

  return (
    <Head>
      <title>{title || DEFAULT_TITLE}</title>

      <meta name="description" content={description || DEFAULT_DESCRIPTION} />
      <meta name="keywords" content={keywords || DEFAULT_KEYWORDS} />

      <meta property="og:title" content={ogTitle || DEFAULT_TITLE} />
      <meta
        property="og:description"
        content={ogDescription || DEFAULT_DESCRIPTION_OPEN_GRAPH}
      />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:image:width" content={ogImageWidth.toString()} />
      <meta property="og:image:height" content={ogImageHeight.toString()} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={typeof window !== 'undefined' ? window.location.href : ''}
      />

      <meta name="theme-color" content={smava.colors.primary.main} />
      <link
        rel="icon"
        type="image/png"
        href="https://cdn.smava.de/icons/32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://cdn.smava.de/icons/64x64.png"
        sizes="64x64"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://cdn.smava.de/icons/96x96.png"
        sizes="96x96"
      />
      <link rel="shortcut icon" type="image/png" href="/favicon.ico" />
      <link rel="apple-touch-icon" href={touchIconUrl} />

      <link rel="manifest" href="/manifest.json" />

      {includedJsonLDs.organization && (
        <script
          id="jsonld-organization"
          type="application/ld+json"
          dangerouslySetInnerHTML={getOrganizationJsonLD()}
        />
      )}
      {includedJsonLDs.website && (
        <script
          id="jsonld-website"
          type="application/ld+json"
          dangerouslySetInnerHTML={getWebsiteJsonLD()}
        />
      )}

      <meta name="robots" content={`${indexString}, ${followString}`} />
    </Head>
  );
};
