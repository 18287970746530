export const EKOMI_URL = 'https://api.ekomi.de/v3';

export const EKOMI_SEAL_WIDTH = 74;
export const EKOMI_SEAL_WIDTH_SMALL = 48;
export const EKOMI_FALLBACK_SEAL = 'gold';
export const EKOMI_FALLBACK_SCORE = '4.9';
export const EKOMI_FALLBACK_NUMBER_OF_RATINGS = 998;
export const EKOMI_MAX_STARS = 5;

export const EKOMI_PAGE_URL =
  'https://www.ekomi.de/bewertungen-smava-gmbh.html';

export const EKOMI_SEAL_BRONZE =
  'https://images.ctfassets.net/czunsr9k5tc8/6fknKbAadiGhjiquJ7VTe9/98c0f9ee40ad36a601d10dfbed738ae8/ekomi_bronze.webp';
export const EKOMI_SEAL_SILVER =
  'https://images.ctfassets.net/czunsr9k5tc8/15xYhflSVLk6cd3chRKZWv/03bc7d69d43d46725f320c6b37083bf2/ekomi_silber.webp';
export const EKOMI_SEAL_GOLD =
  'https://images.ctfassets.net/czunsr9k5tc8/56kQLEunsz2miyWeZVdoiH/130c9893532e7d600949d43a373ceb95/ekomi_gold.webp';

export const EKOMI_STAR_URL_FULL = 'https://www.smava.de/assets/icons/star.svg';
export const EKOMI_STAR_URL_EMPTY =
  'https://www.smava.de/assets/icons/star_empty.svg';
export const EKOMI_STAR_SIZE = 16;
