import useSWR from 'swr';
import { styled } from '@mui/material/styles';

import { EKOMI_WIDGET_API_ROUTE } from 'lib/constants/api';
import { EKOMI_SEAL_WIDTH_SMALL } from 'lib/api-routes/ekomi/ekomi-constants';

const fetcher = (url: string) => fetch(url).then((res) => res.text());

const SkeletonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`;

const LogoSkeleton = styled('div')(({ theme }) => ({
  width: EKOMI_SEAL_WIDTH_SMALL,
  height: EKOMI_SEAL_WIDTH_SMALL,
  borderRadius: '50%',
  // @ts-ignore:next-line
  backgroundColor: theme.palette.grey[100],
}));

const RatingSkeleton = styled('div')(({ theme }) => ({
  height: EKOMI_SEAL_WIDTH_SMALL,
  width: 120, // Approximation
  marginLeft: 4,
  marginTop: 0,
  borderRadius: '5%',
  // @ts-ignore:next-line
  backgroundColor: theme.palette.grey[100],
}));

export function EkomiWidget() {
  const { data, error, isLoading } = useSWR(EKOMI_WIDGET_API_ROUTE, fetcher);

  if (isLoading) {
    return (
      <SkeletonWrapper data-testid="ekomi-skeleton">
        <LogoSkeleton />
        <RatingSkeleton />
      </SkeletonWrapper>
    );
  }

  if (error) {
    return null;
  }

  const ekomiWidget = data!;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: ekomiWidget,
      }}
    />
  );
}
