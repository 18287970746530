export const BLACK_FRIDAY_FLAG = 'black_friday_flag_variable';

export enum ABTestKeys {
  CAT2766_app_redirectionABCD = '2024-06-12_cat-2766_app_redirection_v2',
  CAT_3151_APP_REDIRECTION_V3 = '2024-11-21_cat-3151_app_redirection_v3',
  CAT3106_Black_friday_banner = '2024-11-05_cat-3106_black_friday_flag',
  CAT3220_Loan_duration_ABC   = '2025_01_28_cat_3220_loan_amount_duration',
}

export enum Variation {
  CONTROL = 'control',
  VARIATION_B = 'variation_b',
  VARIATION_C = 'variation_c',
  VARIATION_D = 'variation_d',
  VARIATION_E = 'variation_e',
}

export const ABTestsConfig: { [key in ABTestKeys]: string[] } = {
  [ABTestKeys.CAT2766_app_redirectionABCD]: [
    'control',
    'variation_b',
    'variation_c',
    'variation_d',
  ],
  [ABTestKeys.CAT3106_Black_friday_banner]: [BLACK_FRIDAY_FLAG],
  [ABTestKeys.CAT_3151_APP_REDIRECTION_V3]: [
    Variation.CONTROL,
    Variation.VARIATION_B, // Grey small sticky banner & double CTA
    Variation.VARIATION_C, // Overlay & light green banner & double CTA
    Variation.VARIATION_D, // Double and swapped CTA
    Variation.VARIATION_E, // Video
  ],
  [ABTestKeys.CAT3220_Loan_duration_ABC]: [
    Variation.CONTROL,
    Variation.VARIATION_B, // duraton 7 to 8 months
    Variation.VARIATION_C, // amount from 20k to 25k 
  ],
};
