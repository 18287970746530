import { Box, Container } from '@mui/material';

interface LegalTextProps {
  children?: React.ReactNode;
  isSEAPage?: boolean;
}

const LegalText: React.FC<LegalTextProps> = ({ children, isSEAPage }) => {
  const fontColor = isSEAPage ? '#ABABAB' : '#707070';

  return (
    <Container maxWidth="lg">
      <Box py={3}>
        <div
          style={{ fontSize: '0.75rem', lineHeight: '1.9', color: fontColor }}
        >
          {isSEAPage && (
            <div>
              <b>Sparen Sie über 35% mit smava: </b>
              <>
                Vgl. der eff. Jahreszinssätze neuer (lt. Bundesbank) mit den
                über smava vermittelten Krediten 2020.{' '}
                <a href="https://www.smava.de/kreditvergleich/">Mehr Infos</a>.
              </>
            </div>
          )}
          <div style={{ color: fontColor }}>{children}</div>
        </div>
      </Box>
    </Container>
  );
};
export default LegalText;
